import React, { useRef, useState } from 'react'
import { Easing } from 'tweenkle'
import { useAnimateProps } from 'react-animate-props'
import { useInterval } from 'react-use'

const RIGHT = 'RIGHT'
const LEFT = 'LEFT'

function useCarousel (images) {
  const [active, setActive] = useState(0)
  const sliderRef = useRef()

  const [target, setTarget] = useState(0)
  const tweenValue = useAnimateProps(target, {
    ease: Easing.Cubic.InOut,
    delay: 250,
    duration: 800,
    onAnimateProgress: value => {
      return Math.round(value)
    },
    onAnimateComplete: value => {
      return Math.round(value)
    },
  })
  const [direction, setDirection] = useState(RIGHT)
  const moveRight = () => setTarget(sliderRef.current.scrollLeft + (sliderRef.current.scrollWidth / images.length))
  const moveLeft = () => setTarget(sliderRef.current.scrollLeft - (sliderRef.current.scrollWidth / images.length))
  useInterval(
    () => {
      if (active === images.length - 1 && direction === RIGHT) {
        setDirection(LEFT)
        return moveLeft()
      }
      if (active === 0 && direction === LEFT) {
        setDirection(RIGHT)
        return moveRight()
      }
      if (direction === RIGHT) {
        moveRight()
      } else {
        moveLeft()
      }

    }, 3500)

  if (sliderRef.current) {
    sliderRef.current.scrollLeft = tweenValue
  }
  return { setActive, sliderRef }
}

const Carousel = ({ images = [] }) => {

  const { setActive, sliderRef } = useCarousel(images)
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6">
      <div className="snap overflow-hidden relative max-w-3xl mx-auto flex-no-wrap flex transition rounded-lg" ref={sliderRef}
           onScroll={(e) => setActive(Math.round(e.target.scrollLeft / (e.target.scrollWidth / images.length)))}>
        {images.map(it =>

          <div key={it.alt}
               className="w-full flex-shrink-0 text-black flex items-center justify-center rounded-lg shadow-lg">
            <picture>
              <source srcSet={it.webp} type="image/webp" />
              <source srcSet={it.png} type="image/jpeg" />
              <img className="rounded-lg shadow-lg mx-auto" width="1184" height="570" src={it.png} alt={it.alt} />
            </picture>
          </div>)}
      </div>
    </div>
  )
}

export default Carousel

export const Carousel2 = ({ images = [] }) => {
  const { setActive, sliderRef } = useCarousel(images)
  return (<div className="absolute inset-0" ref={sliderRef}
               onScroll={(e) => setActive(Math.round(e.target.scrollLeft / (e.target.scrollWidth / images.length)))}>
      {images.map(it =>
        <div key={it.alt}
             className="w-full flex-shrink-0 text-black flex items-center justify-center rounded-lg shadow-lg">
          <picture className="h-full w-full object-cover">
            <source srcSet={it.webp} type="image/webp" />
            <source srcSet={it.png} type="image/jpeg" />
            <img className="h-full w-full object-cover" width="1184" height="570" src={it.png} alt={it.alt} />
          </picture>
        </div>)}
      <div
        className="absolute inset-0 bg-gradient-to-r from-blue-100 to-blue-400 mix-blend-multiply"></div>
    </div>

  )
}

