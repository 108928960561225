import React from 'react'
import Layout from '../components/layout'
import banner1 from '../static/front/1.jpg'
import banner1WebP from '../static/front/1.webp'
import banner2 from '../static/front/2.jpg'
import banner2WebP from '../static/front/2.webp'
import banner3 from '../static/front/3.jpg'
import banner3WebP from '../static/front/3.webp'
import banner4 from '../static/front/4.jpg'
import banner4WebP from '../static/front/4.webp'
import banner5 from '../static/front/5.jpg'
import banner5WebP from '../static/front/5.webp'
import banner6 from '../static/front/6.jpg'
import banner6WebP from '../static/front/6.webp'
import { Link } from 'gatsby'
import { JoinNewsLetterButton } from '../components/Common/NewsLetterPlug'
import Carousel from '../components/Common/Carousel/Carousel'
import { PageTitle } from '../components/Common/PageTitle'

const images = [
  { png: banner3, webp: banner3WebP, alt: 'Wax ready to be carved!' },
  { png: banner1, webp: banner1WebP, alt: 'Tools of the trade' },
  { png: banner4, webp: banner4WebP, alt: 'Cute charms' },
  { png: banner2, webp: banner2WebP, alt: 'Rings ready for battle' },
  { png: banner5, webp: banner5WebP, alt: 'Wax slates' },
  { png: banner6, webp: banner6WebP, alt: 'Stone setting' },
]
const Index = () => (
  <Layout>
    <div className="relative overflow-hidden">
      <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="mx-auto max-w-screen-xl sm:px-6">
          <PageTitle title="Do you want to learn wax carving?" />
          <div className="text-leading mt-12">

            <Carousel images={images} />

            <p
              className="px-3 mt-1  prose prose-indigo prose-lg max-w-md mx-auto text-base text-gray-700 sm:text-lg md:mt-5 md:text-l md:max-w-3xl">

              <br />
              You've come to the right place! <br /><br />You can find online classes on how to make jewellery from
              wax
              here. Through step by step instructional videos, you learn how to make a piece of jewellery. A handout
              with written instructions and photos is included as well.
              <br
              /><br /> A new class, focused on a specific piece of jewellery is released on the first of each month.
              You
              learn to make beautiful wax pieces that you can cast in metal. But more importantly, each month you
              learn
              new skills and techniques to improve your jewellery making.
            </p>

            <p
              className="px-3  prose prose-indigo prose-lg t-1 max-w-md mx-auto text-base text-gray-700 sm:text-lg mt-6 md:text-l md:max-w-3xl">

              Check out the available <Link to="/classes" className="text-blue-700">classes
            </Link>
            </p>
          </div>
        </div>
      </div>
      <JoinNewsLetterButton />
    </div>
  </Layout>
)

export default Index
